import React, { useState } from "react";
import "../components/ProductBox.scss";
import Modal from "../components/Modal";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

function ProductBox(props) {
  const [showMore, setShowMore] = useState(false);
  let hasVideo = props.video;

  const Bold = ({ children }) => <strong>{children}</strong>;
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  const newDescription = renderRichText(props.description, options);

  return (
    <>
      <div className="col-md-6 product-box-container" id={props.id}>
        <div className="product-box">
          <div className={`product-box-texts ${showMore ? "expanded" : ""} `}>
            <h2>{props.title}</h2>
            {/* <p>{props.description}</p> */}
            {/*  {showMore ? `${props.description} ` : `${props.description.substring(0, 110)}...`} */}
            <div className="product-box-descr">{newDescription}</div>
            {showMore && hasVideo ? (
              <button
                type="button"
                className="btn btn-modal-trigger"
                data-toggle="modal"
                data-target={"#" + props.video}
              >
                {props.btn_video_label}
              </button>
            ) : null}
          </div>
          <button className={`btn-text ${showMore ? "expanded" : ""}`} onClick={() => setShowMore(!showMore)}>
            {showMore ? props.see_less_label : props.see_more_label}
          </button>
        </div>
      </div>

      {hasVideo ? <Modal video={props.video} /> : null}
    </>
  );
}

export default ProductBox;
