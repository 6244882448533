import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Navbar from "../../components/Navbar-en";
import { Helmet } from "react-helmet";
import HeroSection from "../../components/HeroSection";
import ProductBox from "../../components/ProductBox";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer-en";
import WhatsappButton from "../../components/WhatsappButton";

import { slugify } from "../../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

/* import heroImg from "../../images/productos_header.jpg"; */

import "../../scss/main.scss";

const ProductosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct(sort: { fields: title_en, order: ASC }) {
        nodes {
          description_en {
            raw
          }
          title_en
          video_id
        }
      }
      allContentfulProductsPageTemplate {
        nodes {
          hero_title_en
          hero_description_en {
            hero_description_en
          }
          imageHero {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const pageTitle = "Products | Virtual Ed Global"
  const heroTitle = data.allContentfulProductsPageTemplate.nodes[0].hero_title_en;
  const heroDescription = data.allContentfulProductsPageTemplate.nodes[0].hero_description_en.hero_description_en;
  const heroImg = data.allContentfulProductsPageTemplate.nodes[0].imageHero.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/en/products" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />
        <section className="product-boxes">
          <div className="container">
            <div className="row">
              {data.allContentfulProduct.nodes.map((item) => {
                return (
                  <ProductBox
                    title={item.title_en}
                    description={item.description_en}
                    id={slugify(item.title_en)}
                    key={item.title_en}
                    video={item.video_id}
                    see_more_label="See More"
                    see_less_label="Se Less"
                    btn_video_label="Watch Video"
                  />
                );
              })}
            </div>
          </div>
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>We are here to talk about Higher Education.</strong> Share your comments with us.
              </p>
            }
            lang="en"
            button_label="Contact"
          />
        </section>
      </div>
      
      <WhatsappButton lang="en" />
      <Footer />
    </main>
  );
};

export default ProductosPage;
